
import './App.css';
import Home from './Pages/Home';
import {BrowserRouter as Route, Switch, Router} from 'react-router'


function App() {
  return (
    <div className="App">
      
    <Home/>
    </div>
  );
}

export default App;
