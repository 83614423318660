export const array= [
  {
    id:1,
    image:"./image/black.png",
    desc:"Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really saves me time and effort. TOTC is exactly what our business has been lacking.",
    name:"Nicole Smith"
  },

  {
    id:2,
    image:"./image/editman.png",
    desc:"I can't express my gratitude enough for the incredible support I received. It's everything I've been searching for, and I guarantee you won't be disappointed. ",
    name:"Adam Colton"
  }
]