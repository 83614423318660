import styled from 'styled-components'

const Container = styled.div``
const Wrapper = styled.div`
background-image:url('./image/rect.png');
height:200vh;
background-repeat:no-repeat;
  
`

export default function Circle(){
  return(
    
    <Container>

      <Wrapper></Wrapper>
    </Container>
  )
}